@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

:global(.Button).SubscribeButton {
  border: 2px solid $blue;
  background: #fff;
  color: inherit;

  &:hover {
    border: 2px solid $hoverBlue;
    background: $lightGrey;
    color: inherit;
  }
}

.LocationDetail_nav {
  a {
    cursor: pointer;
  }

  .SubscribeButton {
    padding-top: 17px;
    margin-top: .5rem;
    border: 2px solid $blue;
    background: #fff;
    color: inherit;

    &:hover {
      border: 2px solid $hoverBlue;
      background: $lightGrey;
      color: inherit;
    }
  }

  @mixin above $medium {
    padding: 0 3rem;

    ul {
      display: flex;
      align-items: center;
      width: 100%;

      .SubscribeButton {
        margin-top: 0;
        height: 2.5rem;
        padding-top: 11px;
        padding-bottom: 9px;
      }
    }
  }
}

.LocationDetail_events :global(.Event-truck):first-child {
  @mixin above $medium {
    border-top: 0;
  }
}